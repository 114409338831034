import { AppRoute } from "@/AppRoute";
import { UserRole } from "@/schemaTypes";
import { Trans } from "react-i18next";
import { tourIds } from "./tourIds";
import { Text } from "@chakra-ui/react";

interface Tour {
  tourName: string;
  roles: string[];
  steps: {
    target: string;
    content: string | JSX.Element;
  }[];
}

const onboardingGeneralInfo = {
  tourName: "onboarding-general-info",
  roles: [UserRole.SchoolAdmin],
  steps: [
    {
      target: `#${tourIds.GeneralInfo}`,
      content: (
        <Trans>
          In this section, you will find the General Information of your
          platform and you will be able to review its main settings.
        </Trans>
      ),
      data: {
        next: `${AppRoute.Schools}/current`,
        prev: `${AppRoute.Schools}/current`,
      },
      disableBeacon: true,
      waitForSelector: true,
    },
    {
      target: `#${tourIds.OverviewSchoolUser}`,
      content: (
        <Trans>
          Check the current school information and the configured domain.
        </Trans>
      ),
      data: {
        next: `${AppRoute.Schools}/current`,
        prev: `${AppRoute.Schools}/current`,
      },
    },
    {
      target: `#${tourIds.Overview}`,
      content: (
        <Trans>
          Review the current usage of enrolled users and created courses.
        </Trans>
      ),
      data: {
        next: `${AppRoute.Schools}/current`,
        prev: `${AppRoute.Schools}/current`,
      },
    },
    {
      target: `#${tourIds.OverviewFeatures}`,
      content: (
        <Trans>
          Check the list of available features and those that you can acquire.
        </Trans>
      ),
      data: {
        next: `${AppRoute.Schools}/current`,
        prev: `${AppRoute.Schools}/current`,
      },
    },
  ],
};

const onboardingUiCustomization = {
  tourName: "onboarding-ui-customization",
  roles: [UserRole.SchoolAdmin],
  steps: [
    {
      target: `#${tourIds.UiCustomization}`,
      content: (
        <Trans>🎨 Here you can customize your school's interface.</Trans>
      ),
      disableBeacon: true,
      waitForSelector: true,

      data: {
        next: `${AppRoute.Schools}/current#section=ui-customization`,
        prev: `${AppRoute.Schools}/current#section=ui-customization`,
      },
    },
    {
      target: `#${tourIds.CustomIconPlataform}`,
      content: (
        <Trans>Here you can upload your organization's main logo.</Trans>
      ),
      data: {
        next: `${AppRoute.Schools}/current#section=ui-customization`,
        prev: `${AppRoute.Schools}/current#section=ui-customization`,
      },
    },
    {
      target: `#${tourIds.CustomFaviconPlataform}`,
      content: (
        <Trans>
          This is the space to upload your favicon, the small icon that will
          appear on your browser tabs.
        </Trans>
      ),
      data: {
        next: `${AppRoute.Schools}/current#section=ui-customization`,
        prev: `${AppRoute.Schools}/current#section=ui-customization`,
      },
    },
    {
      target: `#${tourIds.CustomSignIn}`,
      content: (
        <Trans>
          Select the color you want for the icons and header. This color will be
          visible at the top of the screens.
        </Trans>
      ),
      data: {
        next: `${AppRoute.Schools}/current#section=ui-customization`,
        prev: `${AppRoute.Schools}/current#section=ui-customization`,
      },
    },
    {
      target: `#${tourIds.CustomColorsPlataform}`,
      content: (
        <Trans>
          Define the main colors of your platform. These colors will be used for
          buttons and other visual elements. Choose combinations that reflect
          your visual identity.
        </Trans>
      ),
      data: {
        next: `${AppRoute.Schools}/current#section=ui-customization`,
        prev: `${AppRoute.Schools}/current#section=ui-customization`,
      },
    },
    {
      target: `#${tourIds.CustomHeroImage}`,
      content: (
        <Trans>
          Upload a main image that will be used as a featured background on your
          homepage. Make sure it is within the recommended dimensions.
        </Trans>
      ),
      data: {
        next: `${AppRoute.Schools}/current#section=ui-customization`,
        prev: `${AppRoute.Schools}/current#section=ui-customization`,
      },
    },
    {
      target: `#${tourIds.CustomLoginLogo}`,
      content: (
        <Trans>
          Upload a logo to be displayed on the login screen. This logo will help
          users quickly identify your brand when logging in.
        </Trans>
      ),
      data: {
        next: `${AppRoute.Schools}/current#section=ui-customization`,
        prev: `${AppRoute.Schools}/current#section=ui-customization`,
      },
    },
  ],
};

const onboardingMailing = {
  tourName: "onboarding-mailing",
  roles: [UserRole.SchoolAdmin],
  steps: [
    {
      target: `#${tourIds.Messaging}`,
      content: (
        <Trans>
          📧 Set up an email and 🖼️ Header Image to send notifications to your
          users.
        </Trans>
      ),
      data: {
        next: `${AppRoute.Schools}/current#section=messaging`,
        prev: `${AppRoute.Schools}/current#section=messaging`,
      },
      disableBeacon: true,
      waitForSelector: true,
    },
    {
      target: `#${tourIds.CustomMailing}`,
      content: (
        <Trans>
          Do you have a email? You can configure it to send notifications.
        </Trans>
      ),
      data: {
        next: `${AppRoute.Schools}/current#section=messaging`,
        prev: `${AppRoute.Schools}/current#section=messaging`,
      },
    },
    {
      target: `#${tourIds.CustomMailingFeedback}`,
      content: (
        <Trans>
          Enter an email where you will receive feedback from your users.
        </Trans>
      ),
      data: {
        next: `${AppRoute.Schools}/current#section=messaging`,
        prev: `${AppRoute.Schools}/current#section=messaging`,
      },
    },
    {
      target: `#${tourIds.MailHeader}`,
      content: (
        <Trans>
          Upload an image to customize the email header used in notifications.
        </Trans>
      ),
      data: {
        next: `${AppRoute.Schools}/current#section=messaging`,
        prev: `${AppRoute.Schools}/current#section=messaging`,
      },
    },
    {
      target: `#${tourIds.MailFooter}`,
      content: (
        <Trans>Write a message for the footer of the notification email.</Trans>
      ),
      data: {
        next: `${AppRoute.Schools}/current#section=messaging`,
        prev: `${AppRoute.Schools}/current#section=messaging`,
      },
    },
    {
      target: `#${tourIds.CustomMailgun}`,
      content: (
        <Trans>
          Do you have a custom domain? Set up your Mailgun account to manage
          email sending.
        </Trans>
      ),
      data: {
        next: `${AppRoute.Schools}/current#section=messaging`,
        prev: `${AppRoute.Schools}/current#section=messaging`,
      },
    },
  ],
};

const onboardingGoogleAnalytics = {
  tourName: "onboarding-google-analytics",
  roles: [UserRole.SchoolAdmin],
  steps: [
    {
      target: `#${tourIds.GoogleAnalytics}`,
      content: (
        <Trans>
          Set up Google Analytics to track web traffic and gather data on user
          behavior.
        </Trans>
      ),
      data: {
        next: `${AppRoute.Schools}/current#section=google-analytics`,
        prev: `${AppRoute.Schools}/current#section=google-analytics`,
      },
      disableBeacon: true,
      waitForSelector: true,
    },
    {
      target: `#${tourIds.GoogleAnalyticsId}`,
      content: (
        <Trans>
          Enter your Google Tag Manager ID to integrate tags and easily manage
          analytics and tracking tools.
        </Trans>
      ),
      data: {
        next: `${AppRoute.Schools}/current#section=google-analytics`,
        prev: `${AppRoute.Schools}/current#section=google-analytics`,
      },
    },
  ],
};

const onboardingDiscord = {
  tourName: "onboarding-discord",
  roles: [UserRole.SchoolAdmin],
  steps: [
    {
      target: `#${tourIds.DiscordBotToken}`,
      content: (
        <Trans>
          Enter your Discord bot token. This is a unique identifier that allows
          your bot to interact with servers and users.
        </Trans>
      ),
      data: {
        next: `${AppRoute.Schools}/current#section=messaging`,
        prev: `${AppRoute.Schools}/current#section=messaging`,
      },
      disableBeacon: true,
    },
    {
      target: `#${tourIds.DiscordServer}`,
      content: (
        <Trans>
          Provide the Discord server ID you want to connect your bot to or
          integrate specific functionalities with. This ID uniquely identifies
          your server.
        </Trans>
      ),
      data: {
        next: `${AppRoute.Schools}/current#section=messaging`,
        prev: `${AppRoute.Schools}/current#section=messaging`,
      },
    },
    {
      target: `#${tourIds.DiscordFeedbackChannel}`,
      content: (
        <Trans>
          Enter the ID of the Discord channel where user feedback will be
          collected. This channel will allow for centralizing opinions,
          questions, or comments.
        </Trans>
      ),
      data: {
        next: `${AppRoute.Schools}/current#section=messaging`,
        prev: `${AppRoute.Schools}/current#section=messaging`,
      },
    },
    {
      target: `#${tourIds.DiscordCohortsChannel}`,
      content: (
        <Trans>
          Specifies the ID of the parent channel that groups related channels
          into cohorts or groups.
        </Trans>
      ),
      data: {
        next: `${AppRoute.Schools}/current#section=messaging`,
        prev: `${AppRoute.Schools}/current#section=messaging`,
      },
    },
  ],
};

const onboardingLinkedin = {
  tourName: "onboarding-linkedin",
  roles: [UserRole.SchoolAdmin],
  steps: [
    {
      target: `#${tourIds.LinkedIn}`,
      content: <Trans>Set up your LinkedIn.</Trans>,
      data: {
        next: `${AppRoute.Schools}/current#section=linked-in`,
        prev: `${AppRoute.Schools}/current#section=linked-in`,
      },
      disableBeacon: true,
      waitForSelector: true,
      disableOverlayClose: true,
      hideCloseButton: true,
    },
    {
      target: `#${tourIds.LinkedInPage}`,
      content: (
        <Trans>
          Set up your LinkedIn ID for certificate issuance, which will be
          validated through the provided ID.
        </Trans>
      ),
      data: {
        next: `${AppRoute.Schools}/current#section=linked-in`,
        prev: `${AppRoute.Schools}/current#section=linked-in`,
      },
    },
  ],
};
const onboardingDocuments = {
  tourName: "onboarding-documents",
  roles: [UserRole.SchoolAdmin],
  steps: [
    {
      target: `#${tourIds.Documents}`,
      content: <Trans>Set up your documents with rules for students.</Trans>,
      data: {
        next: `${AppRoute.Schools}/current#section=documents`,
        prev: `${AppRoute.Schools}/current#section=documents`,
      },
      disableBeacon: true,
      waitForSelector: true,
    },
    {
      target: `#${tourIds.PrivacyPolicy}`,
      content: (
        <Trans>
          Upload a document that specify the privacy policies for your users.
        </Trans>
      ),
      data: {
        next: `${AppRoute.Schools}/current#section=documents`,
        prev: `${AppRoute.Schools}/current#section=documents`,
      },
    },
    {
      target: `#${tourIds.TermsOfService}`,
      content: (
        <Trans>
          Upload a document that specifies the terms of service. This document
          will be displayed on the login screen for each user.
        </Trans>
      ),
      data: {
        next: `${AppRoute.Schools}/current#section=documents`,
        prev: `${AppRoute.Schools}/current#section=documents`,
      },
    },
    {
      target: `#${tourIds.ConductCode}`,
      content: (
        <Trans>
          Upload a document that promotes a respectful environment free from
          inappropriate behavior for your users.
        </Trans>
      ),
      data: {
        next: `${AppRoute.Schools}/current#section=documents`,
        prev: `${AppRoute.Schools}/current#section=documents`,
      },
    },
    {
      target: `#${tourIds.GradingPolicy}`,
      content: (
        <Trans>
          Do your users need to review a document on evaluation policies? Create
          and upload a document that outlines these policies.
        </Trans>
      ),
      data: {
        next: `${AppRoute.Schools}/current#section=documents`,
        prev: `${AppRoute.Schools}/current#section=documents`,
      },
    },
  ],
};

const onboardingPayment = {
  tourName: "onboarding-payment",
  roles: [UserRole.SchoolAdmin],
  steps: [
    {
      target: `#${tourIds.Payments}`,
      content: <Trans>Set up your payment gateways.</Trans>,
      data: {
        next: `${AppRoute.Schools}/current#section=payments`,
        prev: `${AppRoute.Schools}/current#section=payments`,
      },
      disableBeacon: true,
      waitForSelector: true,
    },
    {
      target: `#${tourIds.AccountId}`,
      content: (
        <Trans>
          Configure the unique identifier of your PayU account, required to
          authenticate your merchant account.
        </Trans>
      ),
      data: {
        next: `${AppRoute.Schools}/current#section=payments`,
        prev: `${AppRoute.Schools}/current#section=payments`,
      },
    },
    {
      target: `#${tourIds.ApiKey}`,
      content: (
        <Trans>Secret key provided by PayU to authenticate API requests.</Trans>
      ),
      data: {
        next: `${AppRoute.Schools}/current#section=payments`,
        prev: `${AppRoute.Schools}/current#section=payments`,
      },
    },
    {
      target: `#${tourIds.MerchantId}`,
      content: (
        <Trans>
          Merchant account number in the PayU system, used to process
          transactions.
        </Trans>
      ),
      data: {
        next: `${AppRoute.Schools}/current#section=payments`,
        prev: `${AppRoute.Schools}/current#section=payments`,
      },
    },
    {
      target: `#${tourIds.PaymentsMode}`,
      content: <Trans>Activate production mode to complete the setup.</Trans>,
      data: {
        next: `${AppRoute.Schools}/current#section=payments`,
        prev: `${AppRoute.Schools}/current#section=payments`,
      },
    },
  ],
};

const onboardingStripe = {
  tourName: "onboarding-stripe",
  roles: [UserRole.SchoolAdmin],
  steps: [
    {
      target: `#${tourIds.StripeSecretKey}`,
      content: (
        <Trans>
          Enter the secret key provided by Stripe. This key is required to make
          secure requests to the Stripe API.
        </Trans>
      ),
      data: {
        next: `${AppRoute.Schools}/current#section=payments`,
        prev: `${AppRoute.Schools}/current#section=payments`,
      },
      disableBeacon: true,
    },
    {
      target: `#${tourIds.StripePublicKey}`,
      content: (
        <Trans>
          Enter the public key provided by Stripe. This key is used to interact
          with Stripe securely.
        </Trans>
      ),
      data: {
        next: `${AppRoute.Schools}/current#section=payments`,
        prev: `${AppRoute.Schools}/current#section=payments`,
      },
    },
    {
      target: `#${tourIds.StripeWebhookSecret}`,
      content: (
        <Trans>
          Enter the public key provided by Stripe. This key is used to interact
          with Stripe securely.
        </Trans>
      ),
      data: {
        next: `${AppRoute.Schools}/current#section=payments`,
        prev: `${AppRoute.Schools}/current#section=payments`,
      },
    },
  ],
};

const onboardingStartSchoolAdmin = {
  tourName: "onboarding-start-school-admin",
  roles: [UserRole.SchoolAdmin],
  steps: [
    {
      target: "body",
      content: (
        <Trans>
          Would you like to take a tour to review your school's settings?
        </Trans>
      ),
      placement: "center",
      data: {
        next: `${AppRoute.Dashboard}`,
        prev: `${AppRoute.Dashboard}`,
      },
      title: (
        <Text fontWeight="bold">
          <Trans>Welcome! 🎉</Trans>
        </Text>
      ),
    },

    {
      target: `#${tourIds.SchoolIcon}`,
      content: (
        <Trans>
          In this section, you can manage and customize your school's settings.
        </Trans>
      ),
      data: {
        next: `${AppRoute.Schools}/current`,
        prev: `${AppRoute.Dashboard}`,
      },
    },
    {
      target: `#${tourIds.GeneralInfo}`,
      content: (
        <Trans>
          In this section, you will find the General Information of your
          platform and you will be able to review its main settings.
        </Trans>
      ),
      data: {
        next: `${AppRoute.Schools}/current`,
        prev: `${AppRoute.Schools}/current`,
      },
      disableBeacon: true,
      waitForSelector: true,
    },
    {
      target: `#${tourIds.OverviewSchoolUser}`,
      content: (
        <Trans>
          Check the current school information and the configured domain.
        </Trans>
      ),
      data: {
        next: `${AppRoute.Schools}/current`,
        prev: `${AppRoute.Schools}/current`,
      },
    },
    {
      target: `#${tourIds.Overview}`,
      content: (
        <Trans>
          Review the current usage of enrolled users and created courses.
        </Trans>
      ),
      data: {
        next: `${AppRoute.Schools}/current`,
        prev: `${AppRoute.Schools}/current`,
      },
    },
    {
      target: `#${tourIds.OverviewFeatures}`,
      content: (
        <Trans>
          Check the list of available features and those that you can acquire.
        </Trans>
      ),
      data: {
        next: `${AppRoute.Schools}/current`,
        prev: `${AppRoute.Schools}/current`,
      },
    },
    {
      target: `#${tourIds.IconTour}`,
      content: (
        <Trans>
          This icon allows you to start the section tour and explore its main
          features.
        </Trans>
      ),
      data: {
        next: `${AppRoute.Schools}/current`,
        prev: `${AppRoute.Schools}/current`,
      },
    },
  ],
};

export const toursConfig: Record<string, Tour> = {
  "onboarding-general-info": onboardingGeneralInfo,
  "onboarding-ui-customization": onboardingUiCustomization,
  "onboarding-mailing": onboardingMailing,
  "onboarding-linkedin": onboardingLinkedin,
  "onboarding-documents": onboardingDocuments,
  "onboarding-payment": onboardingPayment,
  "onboarding-google-analytics": onboardingGoogleAnalytics,
  "onboarding-start-school-admin": onboardingStartSchoolAdmin,
  "onboarding-discord": onboardingDiscord,
  "onboarding-stripe": onboardingStripe,
};
