/**
 * @license
 * Copyright 2023 Ada School
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { User } from "firebase/auth";
import { config } from "../config";
import { ErrorWithTranslations } from "@/utils/ErrorWithTranslations";
import { t } from "i18next";

let autoRefreshTokenTimer: NodeJS.Timeout | null = null;
const oneMinute = 1000 * 60;

const setAutoRefreshTimer = (tokenExpiresAt: string) => {
  if (tokenExpiresAt) {
    const tokenExpiresAtMillis = parseInt(tokenExpiresAt, 10);

    if (!isNaN(tokenExpiresAtMillis)) {
      const tokenLifetime = tokenExpiresAtMillis - Date.now();
      if (autoRefreshTokenTimer) {
        clearTimeout(autoRefreshTokenTimer);
      }

      const autoRefreshTime =
        tokenLifetime - oneMinute > 0
          ? tokenLifetime - oneMinute
          : tokenLifetime * 0.9;

      autoRefreshTokenTimer = setTimeout(() => {
        // Schedule a next refresh based on token expiration
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        refreshToken();
      }, autoRefreshTime);
    }
  }
};

export const signOut = async (): Promise<void> => {
  localStorage.removeItem("ada-RT");
  localStorage.removeItem("ada-JWT");
  localStorage.removeItem("ada-JWT-EXP");

  localStorage.removeItem(config.JWE_KEY);
  localStorage.removeItem(config.RT_KEY_JWE);
  localStorage.removeItem(config.JWE_EXPIRATION_KEY);

  if (autoRefreshTokenTimer) {
    clearTimeout(autoRefreshTokenTimer);
  }

  await fetch(`${config.AUTH_API_URL}/logout`, {
    method: "POST",
    credentials: "include",
  });
};

export const refreshToken = async (
  cleanTokenIfFailed = false
): Promise<{ token?: string; refreshToken?: string | undefined }> => {
  const refreshTokenResponse = await fetch(`${config.AUTH_API_URL}/refresh`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    credentials: "include",
  });

  const jsonResponse = await refreshTokenResponse.json();

  if (refreshTokenResponse.status !== 200) {
    if (cleanTokenIfFailed) {
      await signOut();
    }

    if (!jsonResponse.translations || jsonResponse.translations.length == 0) {
      throw new Error(jsonResponse.message);
    }

    throw new ErrorWithTranslations({
      message: jsonResponse.message,
      translations: jsonResponse.translations,
      status: refreshTokenResponse.status,
    });
  }

  if (jsonResponse.token) {
    setAutoRefreshTimer(jsonResponse.expiresAt);
  } else {
    if (cleanTokenIfFailed) {
      await signOut();
    }

    throw new Error(t("Error parsing response token"));
  }

  return jsonResponse;
};

export const exchangeToken = async (
  firebaseUser: User,
  idToken: string,
  token?: string
): Promise<void> => {
  const exchangeResponse = await fetch(`${config.AUTH_API_URL}/exchange`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ firebaseUser, idToken, token }),
    credentials: "include",
  });

  const jsonResponse = await exchangeResponse.json();

  if (exchangeResponse.status !== 200) {
    if (!jsonResponse.translations || jsonResponse.translations.length == 0) {
      throw new Error(jsonResponse.message);
    }

    throw new ErrorWithTranslations({
      message: jsonResponse.message,
      translations: jsonResponse.translations,
      status: exchangeResponse.status,
    });
  }

  if (jsonResponse.token) {
    setAutoRefreshTimer(jsonResponse.expiresAt);
  } else {
    throw new Error(t("Error parsing response token"));
  }
};

export const signInWithCredentials = async (
  email: string,
  password: string
): Promise<void> => {
  const signInResponse = await fetch(`${config.AUTH_API_URL}/sign-in`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ email, password }),
    credentials: "include",
  });

  const jsonResponse = await signInResponse.json();

  if (signInResponse.status !== 200) {
    if (!jsonResponse.translations || jsonResponse.translations.length == 0) {
      throw new Error(jsonResponse.message);
    }

    throw new ErrorWithTranslations({
      message: jsonResponse.message,
      translations: jsonResponse.translations,
      status: signInResponse.status,
    });
  }

  if (jsonResponse.token) {
    setAutoRefreshTimer(jsonResponse.expiresAt);
  } else {
    throw new Error(t("Error in response token"));
  }
};

export const signUpWithCredentials = async ({
  name,
  email,
  password,
  clientToken,
  domain,
  token,
  returnURL,
}: {
  name: string;
  email: string;
  password: string;
  clientToken: string;
  domain: string;
  token?: string;
  returnURL?: string;
}): Promise<void> => {
  const signUpResponse = await fetch(`${config.AUTH_API_URL}/sign-up`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      email,
      password,
      name,
      clientToken,
      domain,
      token,
      returnURL,
    }),
    credentials: "include",
  });

  const jsonResponse = await signUpResponse.json();

  if (signUpResponse.status !== 200) {
    if (!jsonResponse.translations || jsonResponse.translations.length == 0) {
      throw new Error(jsonResponse.message);
    }

    throw new ErrorWithTranslations({
      message: jsonResponse.message,
      translations: jsonResponse.translations,
      status: signUpResponse.status,
    });
  }

  if (!jsonResponse.id) {
    throw new Error(t("Error registering user"));
  }
};

export const validateEmail = async (token: string): Promise<void> => {
  const validateRequest = await fetch(`${config.AUTH_API_URL}/validate-email`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ token }),
    credentials: "include",
  });

  const jsonResponse = await validateRequest.json();

  if (validateRequest.status !== 200) {
    if (!jsonResponse.translations || jsonResponse.translations.length == 0) {
      throw new Error(jsonResponse.message);
    }

    throw new ErrorWithTranslations({
      message: jsonResponse.message,
      translations: jsonResponse.translations,
      status: validateRequest.status,
    });
  }

  if (!jsonResponse.id) {
    throw new Error(t("Error validating email"));
  }
};

export const resetPassword = async (
  passwordToken: string,
  password: string,
  clientToken: string
): Promise<void> => {
  const resetPassRequest = await fetch(
    `${config.AUTH_API_URL}/reset-password`,
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ passwordToken, password, clientToken }),
      credentials: "include",
    }
  );

  const json = await resetPassRequest.json();

  if (resetPassRequest.status !== 200) {
    if (!json.translations || json.translations.length == 0) {
      throw new Error(json.message);
    }

    throw new ErrorWithTranslations({
      message: json.message,
      translations: json.translations,
      status: resetPassRequest.status,
    });
  }
};

export const forgotPassword = async (
  email: string,
  clientToken: string,
  domain: string
): Promise<void> => {
  const forgotPassRequest = await fetch(
    `${config.AUTH_API_URL}/forgot-password`,
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email, clientToken, domain }),
      credentials: "include",
    }
  );

  const json = await forgotPassRequest.json();

  if (forgotPassRequest.status !== 200) {
    if (!json.translations || json.translations.length == 0) {
      throw new Error(json.message);
    }

    throw new ErrorWithTranslations({
      message: json.message,
      translations: json.translations,
      status: forgotPassRequest.status,
    });
  }
};

export const singUpShoolCredentials = async ({
  schoolName,
  subdomain,
  userName,
  email,
  password,
  clientToken,
  returnURL,
}: {
  schoolName: string;
  subdomain: string;
  userName: string;
  email: string;
  password: string;
  clientToken: string;
  returnURL: string;
}): Promise<void> => {
  const schoolResponse = await fetch(`${config.AUTH_API_URL}/sign-up/school`, {
    method: "POST",
    headers: { "content-type": "application/json" },
    body: JSON.stringify({
      schoolName,
      subdomain,
      userName,
      email,
      password,
      clientToken,
      returnURL,
    }),
    credentials: "include",
  });

  const jsonResponse = await schoolResponse.json();

  if (schoolResponse.status !== 200) {
    if (!jsonResponse.translations || jsonResponse.translations.length == 0) {
      throw new Error(jsonResponse.message);
    }

    throw new ErrorWithTranslations({
      message: jsonResponse.message,
      translations: jsonResponse.translations,
      status: schoolResponse.status,
    });
  }

  if (!jsonResponse.id) {
    throw new Error(t("Error registering school"));
  }
};
