export enum tourIds {
  SchoolIcon = "schoolIcon",
  GeneralInfo = "general-info",
  OverviewSchoolUser = "overview-school-user",
  Overview = "overview-plan",
  OverviewFeatures = "overview-features",
  UiCustomization = "ui-customization",
  CustomIconPlataform = "custom-icon-plataform",
  CustomFaviconPlataform = "custom-favicon-plataform",
  CustomSignIn = "custom-sign-in",
  CustomColorsPlataform = "custom-colors-plataform",
  CustomHeroImage = "custom-hero-image",
  CustomLoginLogo = "custom-login-logo",
  Messaging = "messaging",
  CustomMailing = "custom-mailing",
  CustomMailingFeedback = "custom-mailing-feedback",
  MailHeader = "mail-header",
  MailFooter = "mail-footer",
  CustomMailgun = "custom-mailgun",
  LinkedIn = "linked-in",
  LinkedInPage = "linked-in-page",
  Documents = "documents",
  PrivacyPolicy = "privacy-policy",
  TermsOfService = "terms-of-service",
  ConductCode = "conduct-code",
  GradingPolicy = "grading-policy",
  Payments = "payments",
  AccountId = "account-id",
  ApiKey = "api-key",
  MerchantId = "merchant-id",
  PaymentsMode = "payments-mode",
  DiscordBotToken = "discord-bot-token",
  GoogleAnalytics = "google-analytics",
  GoogleAnalyticsId = "google-analytics-id",
  IconTour = "icon-tour",
  DiscordServer = "discord-server",
  DiscordFeedbackChannel = "discord-feedback-channel",
  DiscordCohortsChannel = "discord-cohorts-channel",
  StripeSecretKey = "stripe-secret-key",
  StripePublicKey = "stripe-public-key",
  StripeWebhookSecret = "stripe-webhook-secret",
}

export enum tourNameIds {
  OnboardingGeneralInfo = "onboarding-general-info",
  OnboardingUiCustomization = "onboarding-ui-customization",
  OnboardingMailing = "onboarding-mailing",
  OnboardingLinkedin = "onboarding-linkedin",
  OnboardingDocuments = "onboarding-documents",
  OnboardingPayment = "onboarding-payment",
  OnboardingGoogleAnalytics = "onboarding-google-analytics",
  OnboardingStartSchoolAdmin = "onboarding-start-school-admin",
  OnboardingDiscord = "onboarding-discord",
  OnboardingStripe = "onboarding-stripe",
}
